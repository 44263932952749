import { useState } from 'react'
import { generateHashString } from '#app/utils/generateHash'
import { cn } from '#app/utils/misc'

interface ImageComponentProps
	extends React.ImgHTMLAttributes<HTMLImageElement> {
	src: string
	size?: 'original' | 'thumbnail'
}

// @todo maybe we should update this to media component
const ImageComponent = ({
	src,
	size = 'original',
	alt,
	className,
	...rest
}: ImageComponentProps) => {
	const [imageSrc, setImageSrc] = useState<string | null>(
		src.includes('media.cdn.bestseller') ? null : src,
	)
	const featureEnabled = ENV.IMAGE_FEATURE === 'true'
	if (!featureEnabled) {
		return <img src={src} alt={alt} {...rest} />
	}

	const handleImageError = () => {
		setImageSrc(`/resources/image?src=${src}&size=${size}`)
	}
	const hashed = generateHashString(src)

	if (src.includes('.mp4')) {
		return (
			<video
				className={cn('relative bg-bestseller-background-beige', className)}
				controls={false}
				autoPlay
				muted
				loop
				playsInline
				style={{ aspectRatio: '3 / 4', objectFit: 'cover' }}
			>
				<source src={src} type="video/mp4" />
				Your browser does not support the video tag.
			</video>
		)
	} else {
		return (
			<img
				src={
					imageSrc ||
					`https://storage.googleapis.com/plenty-prod/images/${size}/${hashed}.webp`
				}
				onError={handleImageError}
				alt={alt}
				className={cn('relative bg-bestseller-background-beige', className)}
				style={{
					aspectRatio: '3 / 4',
					objectFit: 'cover',
					// This is a workaround for the issue with the image not loading
				}}
				{...rest}
			/>
		)
	}
}

export default ImageComponent
